@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@400;600;700&display=swap');

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  outline: 0;
}

html,
body,
img,
fieldset,
abbr,
acronym {
  border: 0;
}

ol,
ul {
  list-style: none;
}

body {
  margin: 24px 16px;
  text-align: center;
  font-family: 'Comfortaa', sans-serif;
  font-size: 16px;
}

a {
  color: #333;
  text-decoration: none;
}

.App {
  text-align: center;
}

.App hr {
  margin-bottom: 24px;
}

.App-header {
  margin-bottom: 56px;
}

.App-logo {
  cursor: pointer;
}

.App-logo img {
  height: 100px;
  pointer-events: none;
  margin-bottom: 32px;
}

.App-breadcrumbs {
  margin-bottom: 32px;
  text-align: left;
}

.App-links {
  max-width: 400px;
  margin: 0 auto;
  font-weight: 600;
}

.App-links ul, App-links li {
  list-style: none;
}

.App-links li {
  margin-bottom: 8px;
}

.App-links a {
  display: block;
  background-color: #DBBAE5;
  color: #333;
  padding: 14px 16px;
  border-radius: 5px;
  text-transform: uppercase;
  width: calc(100% - 32px);
  transition: background-color 0.4s ease;
  cursor:pointer;
}

.App-links a:hover {
  font-weight: 700;
  display: inline-block;
  animation: pulse; /* referring directly to the animation's @keyframe declaration */
  animation-duration: 0.4s; /* don't forget to set a duration! */
  background-color: #B8D0DD;
}

.App-materials {
  display: table;
}

.App-materials_listing {
  display: table-row;
}

.App-materialsByProduct {
  display: inline-block;
  margin: 10px 10px 10px 10px;
}

.App-materials_disclaimer {
  display: table-row;
}

footer {
  padding: 20px;
}

/* =========== START Download Page =========== */

#app-download-container {
	display:flex;
  align-items: center;
  justify-content: center;
}

.app-downloads {
	display: flex;
	flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  border: 0px dotted blue;
  margin: 20px 0 20px 0;
  max-width: 900px;
}

.download-box {
  width: 400px;
  margin: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #eee;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.download-box .name {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.download-box img {
  width: 150px;
  height: 150px;
  margin: 0 30px 0 0;
  border-radius: 5px;
  border: 1px dotted #ccc;
}

.download-box div {
  border: 0px solid red;
  text-align: left;
  width: 240px;
}

.download-box p {
  line-height: 1.5;
}

.download-box .details .format {
  font-size: 15px;
}

.download-box .details .format em {
  font-weight: bold;
  font-style: normal;
}

.download-box a.download {
  display: inline-block;
  background-color: #DBBAE5;
  color: #333;
  padding: 12px 14px;
  border-radius: 10px;
  text-transform: uppercase;
  cursor: pointer;
  font-size: 13px;
  margin: 10px 15px 0 0;
}
.download-box a.download:hover {
  font-weight: 700;
  display: inline-block;
  animation: pulse; /* referring directly to the animation's @keyframe declaration */
  animation-duration: 0.4s; /* don't forget to set a duration! */
  background-color: #B8D0DD;
}

.download-box a.social {
  cursor: pointer;
  position: relative;
  top: 5px;
}

.download-box a.social .bi {
  width: 1em;
  font-size: 25px;
}

.download-box a.social .bi:hover {
  color: blue;
  display: inline-block;
  animation: pulse; /* referring directly to the animation's @keyframe declaration */
  animation-duration: 0.4s; /* don't forget to set a duration! */
}

/* --- Mobile styles --- */
@media (max-width: 576px) {
  .app-downloads {
    max-width: 400px;
    border: 0px solid red;
    margin-left: 20px;
  }

  .download-box {
    max-width: 300px;
    border: 0px solid red;
    font-size: 12px;
  }

  .download-box img {
    width: 100px !important;
    height: 100px !important;
  }

  .download-box .details .format {
    font-size: 13px !important;
  }

  .download-box a.download {
    padding: 8px 12px !important;
    font-size: 11px !important;
  }
}

/* =========== END Download Page =========== */